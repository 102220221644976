import { FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import IconRounded from "../../components/IconRounded";
import {
  dataProcessing,
  energy,
  facility,
  garden,
  pool,
  water
} from "../../components/Images";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const RowList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -50px;
  margin-right: -50px;

  ${[theme.media.maxM]} {
    margin-left: -15px;
    margin-right: -15px;
  }
`;

const ColList = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 50px;
  padding-right: 50px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const IconList = styled.div`
  position: relative;
  padding-left: 162px;
  margin-bottom: 60px;
  ${[theme.media.maxS]} {
    padding-left: 120px;
  }
`;

const IconListContent = styled.div``;

const Icon = styled(IconRounded)`
  position: absolute;
  left: 0;
  top: 0;
`;

const List = styled.ul`
  margin-left: 0;
  padding-left: 20px;
`;

const ListItem = styled.li``;

class UseCases extends React.Component {
  render() {
    return (
      <RowList>
        <ColList>
          <IconList>
            <Icon src={pool} />
            <IconListContent>
              <h4>
                <FormattedMessage id="partials.whyeledio.usecases.pool" />
              </h4>
              <List>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.pool.1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.pool.2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.pool.3" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.pool.4" />
                </ListItem>
              </List>
            </IconListContent>
          </IconList>
        </ColList>
        <ColList>
          <IconList>
            <Icon src={garden} />
            <IconListContent>
              <h4>
                <FormattedMessage id="partials.whyeledio.usecases.ev" />
              </h4>
              <List>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.ev.1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.ev.2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.ev.3" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.ev.4" />
                </ListItem>
              </List>
            </IconListContent>
          </IconList>
        </ColList>
        <ColList>
          <IconList>
            <Icon src={water} />
            <IconListContent>
              <h4>
                <FormattedMessage id="partials.whyeledio.usecases.wastewater" />
              </h4>
              <List>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.wastewater.1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.wastewater.2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.wastewater.3" />
                </ListItem>
              </List>
            </IconListContent>
          </IconList>
        </ColList>
        <ColList>
          <IconList>
            <Icon src={facility} />
            <IconListContent>
              <h4>
                <FormattedMessage id="partials.whyeledio.usecases.power" />
              </h4>
              <List>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.power.1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.power.2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.power.3" />
                </ListItem>
              </List>
            </IconListContent>
          </IconList>
        </ColList>
        <ColList>
          <IconList>
            <Icon src={dataProcessing} />
            <IconListContent>
              <h4>
                <FormattedMessage id="partials.whyeledio.usecases.remote" />
              </h4>
              <List>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.remote.1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.remote.2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.remote.3" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.remote.4" />
                </ListItem>
              </List>
            </IconListContent>
          </IconList>
        </ColList>
        <ColList>
          <IconList>
            <Icon src={energy} />
            <IconListContent>
              <h4>
                <FormattedMessage id="partials.whyeledio.usecases.customer" />
              </h4>
              <List>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.customers.1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.customers.2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="partials.whyeledio.usecases.customers.3" />
                </ListItem>
              </List>
            </IconListContent>
          </IconList>
        </ColList>
      </RowList>
    );
  }
}

export default UseCases;

import React from "react";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

interface IconRoundedProps {
  src: string;
  alt?: string;
  className?: string;
}

const IconRoundedContent = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  border-radius: 50%;
  background-color: ${theme.colors.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  ${[theme.media.maxS]} {
    width: 80px;
    height: 80px;
    margin-bottom: 25px;
  }
`;

const IconRoundedImage = styled.img`
  max-width: 47px;
  height: auto;
  ${[theme.media.maxS]} {
    max-width: 40px;
  }
`;

class IconRounded extends React.Component<IconRoundedProps> {
  render() {
    const { src, alt, className } = this.props;

    return (
      <IconRoundedContent className={className}>
        <IconRoundedImage src={src} alt={alt} />
      </IconRoundedContent>
    );
  }
}

export default IconRounded;

import { FormattedHTMLMessage, FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const Advantages = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Advantage = styled.div`
  max-width: calc(30% - 70px);
  flex-basis: calc(30% - 70px);
  padding-top: 50px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
    padding-top: 30px;
  }
`;

const AdvantagePrimary = styled(Advantage)`
  max-width: 40%;
  flex-basis: 40%;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 80px 60px;
  border-top: 5px solid ${theme.colors.blueDark};
  background-color: ${theme.colors.white};
  margin-left: 70px;
  margin-right: 70px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 50px 30px;
  }
`;

const AdvantagePrimaryHeading = styled.h3`
  strong {
    display: block;
  }
`;

const AdvantageList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const AdvantageListItem = styled.li`
  position: relative;
  padding-left: 29px;
  margin-bottom: 0.5em;
  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    padding: 3px;
    color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
  }
`;

const AdvantageListItemPlus = styled(AdvantageListItem)`
  &::before {
    content: "+";
    background: #aae8ab;
    border-color: #77b585;
  }
`;

const AdvantageListItemMinus = styled(AdvantageListItem)`
  &::before {
    content: "-";
    background: #cecece;
    border-color: #b2b2b2;
  }
`;

class AdvantagesList extends React.Component {
  render() {
    return (
      <Advantages>
        <Advantage>
          <h4>
            <FormattedMessage id="partials.whyeledio.advantages.title1" />
          </h4>
          <AdvantageList>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item1" />
            </AdvantageListItemPlus>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item2" />
            </AdvantageListItemPlus>
            <AdvantageListItemMinus>
              <FormattedMessage id="partials.whyeledio.advantages.item3" />
            </AdvantageListItemMinus>
            <AdvantageListItemMinus>
              <FormattedMessage id="partials.whyeledio.advantages.item4" />
            </AdvantageListItemMinus>
          </AdvantageList>
        </Advantage>
        <AdvantagePrimary>
          <AdvantagePrimaryHeading>
            <FormattedHTMLMessage id="partials.whyeledio.advantages.title2" />
          </AdvantagePrimaryHeading>
          <AdvantageList>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item5" />
            </AdvantageListItemPlus>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item6" />
            </AdvantageListItemPlus>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item7" />
            </AdvantageListItemPlus>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item8" />
            </AdvantageListItemPlus>
          </AdvantageList>
        </AdvantagePrimary>
        <Advantage>
          <h4>
            <FormattedMessage id="partials.whyeledio.advantages.title3" />
          </h4>
          <AdvantageList>
            <AdvantageListItemMinus>
              <FormattedMessage id="partials.whyeledio.advantages.item9" />
            </AdvantageListItemMinus>
            <AdvantageListItemMinus>
              <FormattedMessage id="partials.whyeledio.advantages.item10" />
            </AdvantageListItemMinus>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item11" />
            </AdvantageListItemPlus>
            <AdvantageListItemPlus>
              <FormattedMessage id="partials.whyeledio.advantages.item12" />
            </AdvantageListItemPlus>
          </AdvantageList>
        </Advantage>
      </Advantages>
    );
  }
}

export default AdvantagesList;

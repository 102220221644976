import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import {
  circleLarge,
  loader,
  waveHorizontalLarge,
  waveHorizontalSmall,
  waveVerticalSmall,
  whyIllustration
} from "../components/Images";
import Layout from "../components/Layout";
import CTA from "../partials/CTA";
import AdvantagesList from "../partials/why_eledio/AdvantagesList";
import UseCases from "../partials/why_eledio/UseCases";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 42px;
  padding-bottom: 42px;
  ${[theme.media.maxM]} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const Content1 = styled(Content)`
  background-size: 160px 17px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 240px) top
      115px,
    right calc(${theme.container.background} - 180px) bottom 70px;
  ${[theme.media.maxM]} {
    padding-top: 75px;
  }
`;

const Content2 = styled(Content)`
  position: relative;
  padding-bottom: 80px;
  background-size: 160px 17px, 28px 28px, 17px 47px, 30px 30px, 47px 17px;
  background-image: url(${waveHorizontalLarge}), url(${circleLarge}),
    url(${waveVerticalSmall}), url(${loader}), url(${waveHorizontalSmall});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} - 277px) top 15%,
    left calc(${theme.container.background} - 120px) top 13%,
    left calc(${theme.container.background} - 67px) bottom 62%,
    left 50vw bottom 53%,
    right calc(${theme.container.background} - 127px) bottom 25%;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to top,
      rgba(25, 32, 87, 0.07) 0%,
      rgba(240, 244, 254, 0.06) 100px,
      rgba(240, 244, 254, 0) 100%
    );
  }
`;

const Content3 = styled(Content)`
  padding-top: 220px;
  padding-bottom: 100px;
  ${[theme.media.maxM]} {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;

  ${[theme.media.maxM]} {
    align-items: flex-start;
  }
`;

const RowHeader = styled(Row)`
  position: relative;
`;

const ColText = styled.div`
  max-width: calc(50% + 50px);
  flex-basis: calc(50% + 50px);
  padding-left: 15px;
  padding-right: 15px;
  ${[theme.media.maxM]} {
    max-width: 80%;
    flex-basis: 80%;
  }
`;

const ColImage = styled.div`
  max-width: calc(50% - 50px);
  flex-basis: calc(50% - 50px);
  padding-left: 15px;
  padding-right: 15px;
  ${[theme.media.maxM]} {
    max-width: 20%;
    flex-basis: 20%;
  }
`;

const ImageHeader = styled.img`
  position: relative;
  z-index: -1;
  max-width: calc(100% + 200px);
  margin-left: -150px;
  ${[theme.media.maxM]} {
    position: absolute;
    top: -80px;
    right: -80px;
    width: 50vw;
    z-index: -1;
  }
  ${[theme.media.maxS]} {
    right: 0px;
  }
`;

const HeadingAdvantages = styled.h2`
  margin-top: -180px;
  text-align: center;
  ${[theme.media.maxM]} {
    margin-top: 0;
  }
`;

class WhyEledioPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.whyeledio.title1" }) +
            " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.whyeledio.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.whyeledio.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.whyeledio.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content1>
            <Container>
              <RowHeader>
                <ColText>
                  <h1>
                    <FormattedMessage id="pages.whyeledio.title1" />
                  </h1>
                  <p>
                    <FormattedHTMLMessage id="pages.whyeledio.text1" />
                  </p>
                </ColText>
                <ColImage>
                  <ImageHeader src={whyIllustration} alt="Why Eledio" />
                </ColImage>
              </RowHeader>
            </Container>
          </Content1>
          <Content3>
            <Container>
              <HeadingAdvantages>
                <FormattedMessage id="pages.whyeledio.title3" />
              </HeadingAdvantages>
              <AdvantagesList />
            </Container>
          </Content3>
          <Content2>
            <Container>
              <h2>
                <FormattedMessage id="pages.whyeledio.title2" />
              </h2>
              <UseCases />
            </Container>
          </Content2>
          <CTA
            buttonLink="/contact/"
            buttonText={intl.formatMessage({ id: "pages.whyeledio.ctatext" })}
            claim={intl.formatMessage({ id: "pages.whyeledio.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(WhyEledioPage);
